import React from "react"
import { Helmet } from "react-helmet"
import NavBar from "./navbar"

export default ({ children }) => (
  <>
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Montserrat:400,600,700" rel="stylesheet" type="text/css"></link>
    </Helmet>

    <div style={{ margin: `3rem auto`, maxWidth: 650, padding: `0 1rem` }}>
      <h1>Title</h1>
      <NavBar
        items={ [
          { text: "Pricing", url: "/pricing" },
          { text: "About Us", url: "/about-us" },
          { text: "Contact", url: "/contact" },
        ] }
        primaryColor="#337930" />
      {children}
    </div>
  </>
)
