import React, { ReactNode } from "react"
import styled from "styled-components"

import { Container } from "./utils"

const Button = styled.div`
  color: #9bcc53;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.15s ease;

  border: none;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.6rem 1.25rem;

  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: .25rem;


  color: ${ props => props.textColor || "black" };

  background-color: ${ props => props.color || "#337930" };

  :hover {
    background-color: #4a671f;
  }
`

export interface ButtonProps {
  children: ReactNode
  color: string
  textColor: string
}

export default ( { color, children, textColor }: ButtonProps ) => (
  <Button color={color} textColor={textColor}>
    {children}
  </Button>
)
