import React from "react"
import styled from "styled-components"

import ReviewWidget from "../components/review_widget"
import Layout from "../components/layout"
import { Container } from "../components/utils"

const FormContainer = styled(Container)`
  background-color: white;
`

export default () => {
  return (
    <Layout>
      <FormContainer>
        <form name="contact" method="POST" data-netlify="true" action="/">
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>Your Name: <input type="text" name="name" /></label>
          </p>
          <p>
            <label>Your Email: <input type="email" name="email" /></label>
          </p>
          <p>
            <label>Message: <textarea name="message"></textarea></label>
          </p>
          <p>
            <button type="submit">Send</button>
          </p>
        </form>
      </FormContainer>
    </Layout>
  )
}
