import React from "react"
import styled from "styled-components"

import Button from "./button"
import { Container } from "./utils"

const Wrapper = styled.div`
  background-color: #ffffff;
  border-bottom: 4px solid #0c481e;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  height: auto;

  @media (min-width: 992px) {
    height: 100px;
  }
`

const NavBarContainer = styled(Container)`
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (min-width: 992px) {
    height: 100px;
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  max-height: 40px;
  max-width: 200px;

  @media (min-width: 768px) {
    max-height: 50px;
    max-width: 250px;
  }
`

const LinkContainer = styled.div`
  flex-grow: 1;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinkItem = styled.a`
  color: ${ props => props.color || "black" };
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 1.5em;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

interface NavBarBase {
  text: string
  url: string
}

export interface NavBarItem extends NavBarBase {
  childs?: NavBarBase[]
}

export interface NavBarProps {
  primaryColor: string

  // backgroundColor: string
  // borderBottomColor: string
  // logoImgUrl: string


  items: NavBarItem[]
}

export default ( { items, primaryColor }: NavBarProps ) => (
  <Wrapper>
    <NavBarContainer>
      <LogoContainer>
        <Logo src="https://deanelectric.ca/img/company-logo.png" />
      </LogoContainer>
      <LinkContainer>
        {
          items.map( ( item ) => {
            return <LinkItem href={item.url} color={primaryColor}>{item.text}</LinkItem>
          } )
        }
      </LinkContainer>
      <Button color={"#9bcc53"} textColor={"white"}>Request a quote</Button>
    </NavBarContainer>
  </Wrapper>
)
